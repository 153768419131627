import React from 'react'
import { Link } from 'gatsby'
import scrollTo from 'gatsby-plugin-smoothscroll';


// Styles 
import { SeoTextHolder } from "./SeoText.styles"

const SeoText = () => ( 
    <SeoTextHolder>
        <span>Om oss</span>
        <h2>Det är enkelt med Städfirmor Borås</h2>
        <p>I Borås finns det runt 115 stycken städfirmor som erbjuder städtjänster. 
            Hinner du titta igenom alla och välja rätt städfirma som passar just din lokal? 
            För att du ska spara tid har vi skapat en lokal tjänst som heter
            <strong> <a href="https://www.byggboras.nu/stadfirma-boras">Städfirmor Borås,</a></strong> som gör jobbet åt dig. Vi kontaktar 
            de lokala städfirmorna i Borås och ger de en offertförfrågan. 
            Alla de städfirmor som kan hjälpa dig med städjobbet som du söker, 
            tar kontakt med dig och skickar sin offert till din mail eller 
            kontaktar dig via telefon.
        </p>
    </SeoTextHolder>
)
export default SeoText
