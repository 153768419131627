import React from "react"
import { graphql, useStaticQuery } from 'gatsby'

// Components
import Layout from 'components/Layout'
import SEO from "components/SEO"
import SeoText from "components/SeoText"
import SeoText2 from "components/SeoText2"
import HeroTwoSides from "components/HeroTwoSides"
import IconThree from "components/IconThree"
import OffertForm from "components/OffertForm"

const IndexPage = ({data}) => {


const schema = {
  
    "@context": "https://schema.org",
    "@type": "Organization",
    "name": "Städfirmor Borås",
    "image" : "https://d33wubrfki0l68.cloudfront.net/2a3d2e6172015886584dab53b3e15ece4e41857d/64226/static/ad82d47be03eb5a88f1d8afbab45818b/cleaning.svg",
    "description": "Skicka din offertförfrågan till alla städfirmor i Borås. Städfirmor Borås hjälper dig att hitta rätt städfirma i Borås. Hemstädning, Flyttstäd, Företagsstädning", 
    "location": {
      "@type": "Place",
      "address": {
        "addressLocality": "Borås"  
      }
    },
    "department": [
      "Städfirmor Borås",
      "Hemstädning Borås",	
      "Fönsterputsning Borås",	
      "Företagsstädning Borås",	
      "Flyttstädning Borås",	
      "Trappstädning Borås",	
      "Byggstädning Borås",	
      "Golvvård Borås",
      "Butiksstädning Borås"
    ],
    "location": [
      "Borås"
    ],
    "url": "https://stadfirmorboras.se/",
    "logo": "https://d33wubrfki0l68.cloudfront.net/2a3d2e6172015886584dab53b3e15ece4e41857d/64226/static/ad82d47be03eb5a88f1d8afbab45818b/cleaning.svg"
}

return( 
  <Layout>
    <SEO title="Städfirmor Borås | Skicka offert till städfirmor i Borås" schemaMarkup={schema} />
    <HeroTwoSides /> 
    <SeoText />
    <IconThree />
    <OffertForm />
    <SeoText2 />
  </Layout>
) 
}
export default IndexPage

