import React from 'react'
import { Link } from 'gatsby'
import Image from "gatsby-image"

// Styles 
import { IconsHolder } from "./IconThree.styles"

import { useBilderDataQuery } from 'Hooks/useBilderQuery'

const IconThree = () => {
    const { 
        iconone,
        icontwo,
        iconthree
    } = useBilderDataQuery()

    const form = iconone.childImageSharp.base64.base64;
    const moln = icontwo.childImageSharp.base64.base64;
    const mail = iconthree.childImageSharp.base64.base64;

return(
    <IconsHolder>
        <section>
            <img className="" src={form} alt="Städfirmor Borås" title="Städfirmor Borås"/>
            <h2>Skicka offert</h2>
            <p> Nedan fyller du i 
                alla uppgifter angående 
                önskad städtjänst</p>
        </section>
        <section>
            <img className="" src={moln} alt="Städfirmor Boråsp" title="Städfirmor Borås"/>
            <h2>1min</h2>
            <p> Vi kontaktar samtliga städfirmor
                som vi samarbetar med och 
                skickar ditt offertförfrågan</p>
        </section>
        <section>
            <img className="" src={mail} alt="Städfirmor Borås" title="Städfirmor Borås"/>
            <h2>Offerter kommer</h2>
            <p> Inom kort får du offerter från städfirmor som vill utföra städtjänsten</p>
        </section>
        
    </IconsHolder>
    )
}

export default IconThree