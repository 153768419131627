import React from 'react'
import { Link } from 'gatsby'
import 'animate.css/animate.css'
import scrollTo from 'gatsby-plugin-smoothscroll';


// Styles
import { HeroSides, Logo } from "./HeroTwoSides.styles"

//Hooks 
import { useBilderDataQuery } from 'Hooks/useBilderQuery'
import { useSiteConfigQuery } from 'Hooks/useSiteConfigQuery'


const HeroTwoSides = () => {
     const siteConfig = useSiteConfigQuery()
    // const { 
    //     herobg 
    // } = useBilderDataQuery()
    // const hero = herobg.childImageSharp.base64.base64;

    return (
    <HeroSides >    
        <div className="left" >
        <Logo width="100" height="100" src={siteConfig.logo.publicURL} alt="Städfirmor Borås" title="Städfirmor Borås"/>

        </div>
        <div className="right">
            <h1><strong>Städfirmor Borås?</strong> Då har
                du kommit till rätt  <strong>hemsida.</strong> </h1>
            <div className="knappar"> 
                <a onClick={() => scrollTo('#offert')}  className="animate__animated animate__fadeIn animate__delay-1s" title="Offert">
                    Offert
                </a>
                <Link to="/kontakt" title="Kontakt Städfirmor Borås" className="animate__animated animate__fadeIn animate__delay-2s">
                    Kontakt
                </Link>     
            </div>
            <p className="nationell">
            Fyll i en enkel offertförfrågan där du beskriver din önskan och vi skickar den 
            till samtliga städfirmor i Borås. 
            Det ska inte vara <br/>tidskrävande att hitta eller byta till rätt städfirma. </p>
        </div>
    </HeroSides>
)
}


export default HeroTwoSides 