import styled from "styled-components"

export const IconsHolder = styled.div`
width:100%;
display:flex;
justify-content:space-around;
text-align:center;
background: #f2f2f2;
flex-direction:column;   


@media screen and (min-width: 768px) {
flex-direction:row;   
 }


section {
    flex-grow: 1;
    flex-basis: 0;
    text-align:center;
    padding:35px;

    @media screen and (min-width: 768px) {
        padding:90px 35px;
        }
    

    .gatsby-image-wrapper {
        max-width: 100px;
        margin: 0 auto;
        height: 90px;
        margin-bottom: 35px;
    }

    h2 {
        font-size: 1rem;
        font-weight: bold;

    }
    p {
        font-size:.9rem;
        padding:0px 35px;
    }
}

`