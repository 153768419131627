import React from 'react'
import { Link } from 'gatsby'
import scrollTo from 'gatsby-plugin-smoothscroll';


// Styles 
import { SeoTextHolder2 } from "./SeoText2.styles"

const SeoText2 = () => ( 
    <SeoTextHolder2>
        <h2>Vilken städtjänst behöver du?</h2><br/>
        <p>Behöver du <Link to="/hemstad-boras" title="Hemstäd Borås">hemstädning</Link>, 
        kontorsstädning, <a href="https://www.byggboras.nu/stadfirma-boras">fönsterputs,</a> städning av <a href="https://www.byggboras.nu/stadfirma-boras">trapphus</a> och entré, 
        eller <a href="https://www.byggboras.nu/stadfirma-boras">butiksstädning</a> utifrån behov och krav? Då har vi gjort det 
        enkelt för dig att hitta rätt städfirma i Borås till rätt pris. 
        Du kanske redan har en städfirma, men är inte så nöjd med den. 
        Eller så kanske du har flyttat och är ute efter enbart flyttstäd. 
        Kanske är du nyfiken ifall du kan få en mer prisvärd deal. Allt du 
        behöver göra är fylla i en <a onClick={() => scrollTo('#offert')}  title="Offert"> offertförfrågan. </a>
        Därefter får du alla dina svar skickade till din mail, 
        eller så kontaktas du via telefon av de lokala städfirmor..<br/>
        Målet med vår tjänst är att fungera som en enkel kommunikationskanal 
        mellan samtliga städfirmor och kunder, som behöver hjälp att hitta rätt 
        städfirma, till rätt pris. Det är därför vi har utvecklat tjänsten som gör 
        det enkelt för kunder att ta kontakt med samtliga städfirmor i Borås. 
        Oavsett om du använder tjänsten som privatperson eller som företagskund, 
        så ska du kunna få den hjälp du behöver, till rätt pris.{/*  Vi samarbetar med olika flyttfirmor 
        och ifall du ska flytta snart och är ute en flyttfirma som utför flytt i Göteborg så kan rekomenderar vi 
        <strong><a href="https://xn--flyttmnnen-v5a.se/" target="_blank" rel="noreferrer"> Flyttfirma Göteborg</a></strong>. De utför flytt i Borås 
        också och har bra priser för sina tjänster, du kan läsa mer om deras <a href="https://xn--flyttmnnen-v5a.se/flyttfirma-boras" rel="noreferrer" target="_blank"><strong>flyttjänster i Borås här.</strong></a>  */}
        </p>

    </SeoTextHolder2>
 
)

export default SeoText2
