import styled from "styled-components"

export const SeoTextHolder2 = styled.div`
background:white;
width:100%;
margin:0 auto;
display:flex;
justify-content:center;
align-items:center;
flex-direction:column;
padding-top:50px;
padding-bottom:100px;
 
span {
    text-transform: uppercase;
    letter-spacing: 2px;
    font-size:1.2rem;
    color: #a8885e;
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
}

h2 {
    font-size:1.5rem;
    color:rgba(0,0,0,.7);
    position:relative;
    text-align:center;
    @media screen and (min-width: 768px) {
        font-size:2rem;
    }
}

h3 {
    font-size:1.5rem;
    color:rgba(0,0,0,.7);
    position:relative;
    text-align:center;
    text-transform:uppercase;
    margin-bottom:10px;
    @media screen and (min-width: 768px) {
        font-size:2rem;
    }
}

p {
    text-align:center;
    margin-top:0;
    font-size:1.2rem;
    max-width: 80%;
    a {
        color:black;
        transition:.3s;
        font-weight:bold;
        &:hover {
            color:${props => props.theme.colors.prime};
            cursor: pointer;
            font-weight:bold;
        }
    }
}

.stadsdelar-rubrik {
}
.stadsdelar {
    background:${props => props.theme.colors.blue};
    padding:15px;
    li {
        list-style-type:none;
        color:white;
        margin:10px;
    }
}

 


`